import axios from "../http-common";
import loggerService from "./logger";
import authentication from "../authentication";

export default {
  getProducts(payLoad) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}/FormulationMapping/GetProductListByContriesandBrands`,
        payLoad
      )
      .catch((error) => {
        loggerService.logError(error);
      });
  },
  getProduct(payLoad) {
    return axios
      .get(
        `${
        process.env.VUE_APP_API_URL
      }/FormulationMapping/GetProduct/${payLoad.cIds}/${
        payLoad.productId
      }/${ payLoad.variationId
      }`
      )
      .catch((error) => {
        loggerService.logError(error);
      });
  },
  getProductVariations(payLoad) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}/FormulationMapping/GetProductVariationNamesForDD`,
        payLoad
      )
      .catch((error) => {
        loggerService.logError(error);
      });
  },
  getAssignedFormulation(payLoad) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}/FormulationMapping/GetAssignedFormulation`,
        payLoad
      )
      .catch((error) => {
        loggerService.logError(error);
      });
  },
  getCountriesAndLanguagesByUserAndBrand(payLoad) {
    return axios
      .get(
        `${
        process.env.VUE_APP_API_URL
      }/FormulationMapping/GetCountriesAndLanguagesByUserandBrandName/${authentication.getUserEmail()}/${
        payLoad.brand }/${payLoad.cucCode}/${ payLoad.revision }`
      )
      .catch((error) => {
        loggerService.logError(error);
      });
  }
}